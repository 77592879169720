"use client";

import CartContextProvider from "@/context/CartContext";
import WishlistProvider from "@/context/WishlistContext";
import AuthProvider from "@/app/providers/authProvider";
import ReactQueryProvider from "./reactQuery";
import { ThemeProvider } from "./themeProvider";

export default function Providers({ children }) {
  return (
    <ReactQueryProvider>
      <AuthProvider>
        <WishlistProvider>
          <CartContextProvider>
            <ThemeProvider attribute="class" defaultTheme="system" enableSystem>
              {children}
            </ThemeProvider>
          </CartContextProvider>
        </WishlistProvider>
      </AuthProvider>
    </ReactQueryProvider>
  );
}
