"use client";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { toast } from "@/components/ui/use-toast";
import { Truck } from "lucide-react";
import { useRouter } from "next/navigation";
import { useState } from "react";

export default function TrackOrder() {
  const [trackNumber, setTrackNumber] = useState(null);
  const router = useRouter();

  const handleTrackNumber = (e) => {
    e.preventDefault();

    if (trackNumber) {
      toast({
        title: "Track Order Success",
        description: `Query of ${trackNumber} order has been sent`,
      });
      setTrackNumber("");
      router.push(`/orders/${trackNumber}`);
    }
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <div className="flex gap-1 items-center cursor-pointer">
          <Truck size={20} color="white" />
          <span className=" text-white text-sm">
            Track My Order
          </span>
        </div>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Track Order</DialogTitle>
          <DialogDescription>
            Put your order number, We&apos;re processing it with care and will
            update you shortly.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className=" items-center gap-4">
            <form onSubmit={handleTrackNumber} className="space-y-3">
              <Label htmlFor="Order Number" className="text-right">
                Order Number
              </Label>
              <Input
                name="trackNumber"
                value={trackNumber}
                onChange={(e) => setTrackNumber(e.target.value)}
              />
              <DialogClose asChild>
                <Button
                  disabled={!trackNumber}
                  type="submit"
                  className="flex justify-end w-fit"
                >
                  Submit
                </Button>
              </DialogClose>
            </form>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
