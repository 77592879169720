"use client";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetTrigger,
} from "@/components/ui/sheet";
import { categories } from "@/lib/categories";
import { Menu, ChevronDown } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import { useState } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

export default function Sidebar() {
  const [open, setOpen] = useState(false);
  const [menuName, setMenuName] = useState("");
  return (
    <div className="lg:hidden">
      <Sheet>
        <SheetTrigger asChild>
          <div className="w-10 h-10 border rounded-md flex items-center justify-center cursor-pointer">
            <Menu size={20} className="text-gray-600" />
          </div>
        </SheetTrigger>
        <SheetContent
          side="left"
          className="w-[320px] sm:w-[320px]  overflow-y-auto scrollbar-thumb-gray-100 scrollbar-track-gray-100"
        >
          <SheetHeader>
            <Link href="/" className="">
              <Image
                src="https://ichchapuron.nyc3.cdn.digitaloceanspaces.com/ichchapuron/ichchapuron-bangla-logo.png"
                alt="ichchapuron"
                className="w-24 md:w-32"
                width={100}
                height={100}
              />
            </Link>
          </SheetHeader>
          <div className="grid mt-2 pb-5 divide-y overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
            <Accordion type="single" collapsible className="w-full">
              {categories?.map((data, index) => {
                return data?.subCategory?.length ? (
                  <AccordionItem
                    key={index + data?.id}
                    value={index + 1}
                    className="border-b border-[#c6bdb7]"
                  >
                    <AccordionTrigger>
                      <Link
                        className="cursor-pointer py-1 text-base capitalize font-medium"
                        href={`${data?.link}`}
                      >
                        <SheetClose>{data?.title}</SheetClose>
                      </Link>
                    </AccordionTrigger>
                    <AccordionContent>
                      {data?.subCategory?.length && (
                        <>
                          {data?.subCategory
                            ?.slice(0, 10)
                            ?.map((item, index) => (
                              <div
                                key={index + item?.id}
                                className="flex flex-col bg-custom-warm border-t"
                              >
                                <Link
                                  key={index}
                                  href={item?.path}
                                  className="border-t border-[#dcd6d2] bg-custom-warm px-4 py-2.5 text-sm capitalize"
                                >
                                  {" "}
                                  <SheetClose> {item?.name}</SheetClose>
                                </Link>{" "}
                              </div>
                            ))}

                          {data?.subCategory?.length > 10 && (
                            <Link
                              href="/category"
                              title="Explore Products Categories"
                            >
                              <SheetClose className="mx-4 py-2 px-4 text-sm text-left hover:border-b border-[#c6bdb7]rounded-lg text-white  bg-custom-blue cursor-pointer">
                                See All Categories
                              </SheetClose>
                            </Link>
                          )}
                        </>
                      )}
                    </AccordionContent>
                  </AccordionItem>
                ) : (
                  <Link
                    href={data?.link ? data?.link : "/"}
                    className="text-base capitalize font-medium py-4 border-b border-[#c6bdb7] block "
                  >
                    <SheetClose className="w-full text-left">
                      {data?.title}
                    </SheetClose>
                  </Link>
                );
              })}
            </Accordion>
          </div>
        </SheetContent>
      </Sheet>
    </div>
  );
}
