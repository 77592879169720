import(/* webpackMode: "eager" */ "/workspace/app/globals.css");
import(/* webpackMode: "eager" */ "/workspace/app/providers/providers.jsx");
import(/* webpackMode: "eager" */ "/workspace/app/views/header/Navbar.jsx");
import(/* webpackMode: "eager" */ "/workspace/components/ui/separator.jsx");
import(/* webpackMode: "eager" */ "/workspace/components/ui/toaster.jsx");
import(/* webpackMode: "eager" */ "/workspace/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/workspace/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/workspace/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/workspace/node_modules/nextjs-toploader/dist/index.js")