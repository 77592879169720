"use client";
import { createContext, useState, useEffect } from 'react';

export const WishlistContext = createContext();

const WishlistProvider = ({ children }) => {
  const [wishlist, setWishlist] = useState(typeof window !== "undefined" && localStorage.getItem('wishlist') ? JSON.parse(localStorage.getItem('wishlist')) : []);

  useEffect(() => {
     const storedWishlist = localStorage.getItem('wishlist');
    if (storedWishlist) {
      setWishlist(JSON.parse(storedWishlist));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('wishlist', JSON.stringify(wishlist));
  }, [wishlist]);

  const addToWishlist = (product) => {
    setWishlist([...wishlist, product]);
  };

  const removeFromWishlist = (productId) => {
    const updatedWishlist = wishlist.filter((product) => product._id !== productId);
    setWishlist(updatedWishlist);
  };

  const toggleWishlist = (product) => {
    const index = wishlist.findIndex((item) => item._id === product._id);
    if (index === -1) {
      addToWishlist(product);
    } else {
      removeFromWishlist(product._id);
    }
  };

  return (
    <WishlistContext.Provider value={{ wishlist, toggleWishlist, addToWishlist, removeFromWishlist }}>
      {children}
    </WishlistContext.Provider>
  );
};

export default WishlistProvider;