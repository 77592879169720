"use client"
import { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/navigation'
import { useDebounce } from 'use-debounce'
import { Search } from 'lucide-react'

export default function SearchInput({ search }) {
  const router = useRouter()
  const initialRender = useRef(true)

  const [text, setText] = useState(search)
  const [query] = useDebounce(text, 300)

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false
      return
    }

    if (!query) {
      router.push(`/`)
    } else {
      router.push(`/products?search=${query}`);
    }
  }, [query])

  return (
    <div className="bg-gray-100 flex border-2 max-md:order-1 border-transparent focus-within:border-gray-200 px-4 rounded-sm h-10 md:h-[44px] lg:w-2/4 max-md:w-full">
      <Search className='text-gray-400 mt-2 mr-2'/>
      <input
        type='text'
        placeholder="Search Authors, Books, Electronics etc."
        value={text}
        onChange={e => setText(e.target.value)}
        className="w-full !outline-none !border-none focus:!outline-none focus:!ring-0 bg-transparent text-[#333] text-sm" />
    </div>
  );
}