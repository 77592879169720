"use client";
import { useSession } from "next-auth/react";
import { useState } from "react";
import { BsGlobe } from "react-icons/bs";
import TrackOrder from "./TrackOrder";

const Topbar = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("en");

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };
  const { data: session } = useSession();

  return (
    <div className="bg-custom-blue">
      <div className="container mx-auto h-12 flex justify-end items-center gap-4">
        {session?.user?.email && <TrackOrder />}
        {/* <div className="flex gap-2 items-center text-sm">
                    <BsGlobe size={16} color="white" />
                    <select
                        id="language-select"
                        value={selectedLanguage}
                        onChange={handleLanguageChange}
                        className="focus:ring-0 focus:outline-none"
                    >
                        <option value="en">English</option>
                        <option value="bn">Bangla</option>
                    </select>
                </div> */}
      </div>
    </div>
  );
};

export default Topbar;
