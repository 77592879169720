"use client";
import { useContext, useEffect, useState } from "react";
import Topbar from "./Topbar";
import Search from "./Search";
import Link from "next/link";
import { Heart, User } from "lucide-react";
import { ShoppingCart } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { CartContext } from "@/context/CartContext";
import ProfileMenu from "./ProfileMenu";
import { useSession } from "next-auth/react";
import { WishlistContext } from "@/context/WishlistContext";
import { categories } from "@/lib/categories";
import Sidebar from "./Sidebar";
import Image from "next/image";

const Navbar = () => {
  const { cart } = useContext(CartContext);
  const { data: session } = useSession();
  const [cartProducts, setCartProducts] = useState([]);
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    setCartProducts(cart);
  }, [cart]);

  const { wishlist } = useContext(WishlistContext);
  const [wishlistCount, setWishlistCount] = useState(0);

  useEffect(() => {
    setWishlistCount(wishlist.length);
  }, [wishlist]);

  useEffect(() => {
    const scroll = (event) => {
      setScroll(window.scrollY);
    };
    window.addEventListener("scroll", scroll, false);
  }, []);

  return (
    <>
      <header
        className={` bg-white w-full shadow min-h-[70px] transition duration-500`}
      >
        <Topbar />
        <div className="container mx-auto flex flex-wrap items-center justify-between py-3 lg:gap-y-4 gap-1 gap-y-2 sm:gap-y-6 gap-x-4">
          <div className="flex items-center">
            <Sidebar />
            <Link href="/" className="ml-2 lg:ml-0">
              <Image
                src="https://ichchapuron.nyc3.cdn.digitaloceanspaces.com/ichchapuron/ichchapuron-bangla-logo.png"
                alt="ichchapuron"
                width={120}
                height={100}
                className="w-24 md:w-32"
              />
            </Link>
          </div>
          <Search />
          <div className="flex items-center space-x-4 sm:space-x-8 max-md:ml-auto lg:w-auto overflow-hidden">
            <div className="relative sm:flex text-sm font-medium cursor-pointer hidden">
              <a href="/wishlist">
                <Heart className="" aria-label={`heart icon fot wishlist`} />
                <span className="bg-[#a80000] text-xs px-1.5 font-semibold min-w-[20px] h-5 flex items-center justify-center text-white rounded-full absolute -top-2 left-[60%]">
                  {wishlistCount}
                </span>
              </a>
            </div>
            <div className="relative flex text-sm cursor-pointer">
              <a href="/cart">
                <ShoppingCart className="" aria-label={`shopping cart`} />
                <span className="bg-[#a80000] text-xs px-1.5 font-semibold min-w-[20px] h-5 flex items-center justify-center text-white rounded-full absolute -top-2 left-[60%]">
                  {cartProducts?.length || 0}
                </span>
              </a>
            </div>
            {session?.user?.email ? (
              <ProfileMenu className="" />
            ) : (
              <Link href="/auth/login">
                <Button variant="outline" className="flex items-center gap-1">
                  <User size={18} />
                  Sign In
                </Button>
              </Link>
            )}
          </div>
        </div>
        <div
          className={`hidden lg:flex flex-wrap items-center px-10 relative border-t border-gray-200`}
        >
          <div className="flex justify-center lg:space-x-4 max-lg:space-y-3 w-full max-lg:block">
            {categories?.map((data, index) => {
              return (
                <div
                  className="relative group max-lg:border-b max-lg:py-2 px-2 py-2 z-20"
                  key={index}
                >
                  <Link
                    href={data?.link ? data?.link : "/"}
                    className="text-base capitalize font-medium text-custom-black py-2 px-4 border-gray-200"
                  >
                    {data?.title}
                  </Link>
                  {data?.subCategory?.length && (
                    <Card className="hidden pt-4 absolute top-10 group-hover:block w-56 max-h-80 rounded-md overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 shadow-md">
                      {data?.subCategory?.length > 0 ? (
                        <CardContent className="flex flex-col mt-2">
                          {data?.subCategory
                            ?.slice(0, 10)
                            .map((book, index) => (
                              <Link
                                key={index}
                                href={book?.path}
                                className="hover:text-custom-text-blue p-2 text-custom-black hover:bg-gray-100 text-sm font-medium rounded-md"
                              >
                                {book?.name} ({book?.banglaName})
                              </Link>
                            ))}
                          {data?.subCategory?.length > 10 && (
                            <div className="flex justify-center mt-4">
                              <Link
                                href={data?.link}
                                title="Explore Products Categories"
                              >
                                <Button className=" bg-custom-blue text-white">
                                  See more
                                </Button>
                              </Link>
                            </div>
                          )}
                        </CardContent>
                      ) : (
                        <Card className="hidden absolute group-hover:block w-64 p-0 rounded-none border-none pt-0 shadow-sm transition duration-300">
                          {data?.subCategory?.map((book, index) => (
                            <div className="flex flex-col" key={index}>
                              <Link
                                href="/"
                                className="hover:bg-gray-100 px-4 py-2.5 text-sm font-medium"
                              >
                                {book?.name}{" "}
                                {book?.banglaName && book?.banglaName}
                              </Link>
                            </div>
                          ))}
                        </Card>
                      )}
                    </Card>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </header>
      <header
        className={`fixed z-50 -bottom-96 bg-white w-full h-[70px] sm:min-h-20 shadow-md transition duration-700 ${
          scroll > 120 ? "top-0" : "-top-96"
        }`}
      >
        <div className="container mx-auto flex items-center justify-between py-3 lg:gap-y-4 gap-1 gap-y-2 sm:gap-y-6 gap-x-4">
          <div className="flex items-center">
            <Sidebar />

            <Link href="/" className="ml-2 lg:ml-0">
              <Image
                src="https://ichchapuron.nyc3.cdn.digitaloceanspaces.com/ichchapuron/ichchapuron-bangla-logo.png"
                alt="ichchapuron"
                width={100}
                height={100}
                className="w-24 md:w-32 hidden md:block"
              />
            </Link>
          </div>
          <Search />
          <div className="flex items-center sm:space-x-8 order-2">
            <div className="relative md:flex text-sm font-medium cursor-pointer hidden ">
              <a href="/wishlist">
                <Heart className="" aria-label={`heart icon fot wishlist`} />
                <span className="bg-[#a80000] text-xs px-1.5 font-semibold min-w-[20px] h-5 flex items-center justify-center text-white rounded-full absolute -top-2 left-[60%]">
                  {wishlistCount}
                </span>
              </a>
            </div>
            <div className="relative flex text-sm cursor-pointer">
              <a href="/cart">
                <ShoppingCart className="" />
                <span className="bg-[#a80000] text-xs px-1.5 font-semibold min-w-[20px] h-5 flex items-center justify-center text-white rounded-full absolute -top-2 left-[60%]">
                  {cartProducts?.length || 0}
                </span>
              </a>
            </div>
            <div className="hidden sm:block">
              {session?.user?.email ? (
                <ProfileMenu className="" />
              ) : (
                <Link href="/auth/login" className=" hidden md:inline-block">
                  <Button variant="outline" className="flex items-center gap-1">
                    <User size={18} />
                    Sign In
                  </Button>
                </Link>
              )}
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Navbar;
