"use client";
import { LogOut, Tally4, Heart, ShoppingBag, User } from "lucide-react";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { signOut, useSession } from "next-auth/react";
import { useRouter } from "next/navigation";
import { toast } from "@/components/ui/use-toast";
import { getInitials } from "@/lib/getInitials";
import { trimText } from "@/lib/trimText";
import Link from "next/link";

export default function ProfileMenu() {
  const router = useRouter();
  const { data: session } = useSession();

  const handleLogout = (e) => {
    e.preventDefault();
    signOut({ redirect: false, callbackUrl: "/" });
    router.push("/");
    toast({
      title: "Success",
      description: "You are logged out",
    });
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Avatar className="cursor-pointer">
          <AvatarImage
            src={session?.user?.image || "https://github.com/shadcn.png"}
            alt="profile image"
          />
          <AvatarFallback>{getInitials(session?.user?.name)}</AvatarFallback>
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>
          <p className="capitalize text-sm font-medium">
            {trimText(session?.user?.name, 25)}
          </p>
          <p className="text-sm font-light">
            {trimText(session?.user?.email, 25)}
          </p>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem>
            <Link className="cursor-pointer flex items-center" href="/account">
              <User className="mr-2 h-4 w-4" />
              <span>Account</span>
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem>
            <Link className="cursor-pointer flex items-center" href="/orders">
              <ShoppingBag className="mr-2 h-4 w-4" />
              <span>Orders</span>
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem>
            <Link className="cursor-pointer flex items-center" href="/wishlist">
              <Heart className="mr-2 h-4 w-4" />
              <span>Wishlists</span>
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem>
            <Link className="cursor-pointer flex items-center" href="/points">
              <Tally4 className="mr-2 h-4 w-4" />
              <span>Points</span>
            </Link>
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuItem className="cursor-pointer">
          <div onClick={(e) => handleLogout(e)} className="flex items-center">
            <LogOut className="mr-2 h-4 w-4" />
            <span>Log out</span>
          </div>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
