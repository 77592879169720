export function getInitials(name) {
  if (!name || typeof name !== 'string') {
    return '';
  }

  const words = name.split(' ');

  let initials = '';
  for (let i = 0; i < words.length; i++) {
    initials += words[i][0];
  }

  initials = initials?.toUpperCase()?.substring(0, 2);

  return initials;
}


