const { v4: uuidv4 } = require("uuid");

export const categories = [
  {
    title: "Books",
    category: "books",
    banglaTitle: "বই",
    link: "/books-category",
    subCategory: [
      {
        id: uuidv4(),
        name: "Bangla Classic",
        path: "/books?search=bangla classic",
        banglaName: "চিরায়ত বাংলা",
      },
      {
        id: uuidv4(),
        name: "Bangla Translate",
        path: "/books?search=bangla translate",
        banglaName: "বাংলায় অনুদিত",
      },
      {
        id: uuidv4(),
        name: "Mystery",
        path: "/books?search=mystery",
        banglaName: "রহস্য",
      },
      {
        id: uuidv4(),
        name: "Detective",
        path: "/books?search=detective",
        banglaName: "গোয়েন্দা",
      },
      {
        id: uuidv4(),
        name: "Adventure",
        path: "/books?search=adventure",
        banglaName: "রোমাঞ্চ",
      },
      {
        id: uuidv4(),
        name: "Horror",
        path: "/books?search=horror",
        banglaName: "ভৌতিক",
      },
      {
        id: uuidv4(),
        name: "Thriller",
        path: "/books?search=thriller",
        banglaName: "থ্রিলার",
      },
      {
        id: uuidv4(),
        name: "Novel",
        path: "/books?search=novel",
        banglaName: "উপন্যাস",
      },
      {
        id: uuidv4(),
        name: "Poetry",
        path: "/books?search=poetry",
        banglaName: "কবিতা",
      },
      {
        id: uuidv4(),
        name: "Science Fiction",
        path: "/books?search=science fiction",
        banglaName: "বৈজ্ঞানিক কল্পকাহিনী",
      },
      {
        id: uuidv4(),
        name: "Society",
        path: "/books?search=society",
        banglaName: "সমাজ",
      },
      {
        id: uuidv4(),
        name: "Culture",
        path: "/books?search=culture",
        banglaName: "সংস্কৃতি",
      },
      {
        id: uuidv4(),
        name: "History",
        banglaName: "ইতিহাস",
        path: "/books?search=history",
      },
      {
        id: uuidv4(),
        name: "Psychology",
        banglaName: "সাইকোলজি",
        path: "/books?search=psychology",
      },
      {
        id: uuidv4(),
        name: "Parapsychology",
        banglaName: "প্যারা-সাইকোলজি",
        path: "/books?search=parapsychology",
      },
      {
        id: uuidv4(),
        name: "Muktijuddho",
        banglaName: "মুক্তিযুদ্ধ",
        path: "/books?search=muktijuddho",
      },
      {
        id: uuidv4(),
        name: "Bangabondhu",
        banglaName: "বঙ্গবন্ধু বিষয়ক",
        path: "/books?search=bangabondhu",
      },
      {
        id: uuidv4(),
        name: "Bangladesh",
        banglaName: "বাংলাদেশ বিষয়ক",
        path: "/books?search=bangladesh",
      },
      {
        id: uuidv4(),
        name: "History of Bangladesh",
        banglaName: "বাংলাদেশের ইতিহাস",
        path: "/books?search=history of bangladesh",
      },
      {
        id: uuidv4(),
        name: "Sarcasm & Satire",
        banglaName: "রম্য ও ব্যঙ্গরচনা",
        path: "/books?search=sarcasm satire",
      },
      {
        id: uuidv4(),
        name: "Philosophy",
        banglaName: "দর্শন",
        path: "/books?search=philosophy",
      },
      {
        id: uuidv4(),
        name: "Drama",
        banglaName: "নাটক",
        path: "/books?search=drama",
      },
      {
        id: uuidv4(),
        name: "Biographies",
        banglaName: "জীবনী",
        path: "/books?search=biographies",
      },
      {
        id: uuidv4(),
        name: "Auto Biographies",
        banglaName: "আত্ন-জীবনী",
        path: "/books?search=auto biographies",
      },
      {
        id: uuidv4(),
        name: "Memories",
        banglaName: "স্মৃতিকথা",
        path: "/books?search=memories",
      },
      {
        id: uuidv4(),
        name: "Interviews",
        banglaName: "সাক্ষাৎকার",
        path: "/books?search=interviews",
      },
      {
        id: uuidv4(),
        name: "Self-Motivations & Meditations",
        banglaName: "আত্মউন্নয়ন ও মেডিটেশন",
        path: "/books?search=self motivation",
      },
      {
        id: uuidv4(),
        name: "Politics",
        banglaName: "রাজনীতি",
        path: "/books?search=politics",
      },
      {
        id: uuidv4(),
        name: "World Politics",
        banglaName: "বিশ্ব রাজনীতি",
        path: "/books?search=world politics",
      },
      {
        id: uuidv4(),
        name: "Islamic Books",
        banglaName: "ইসলামিক বই",
        path: "/books?search=islamic books",
      },
      {
        id: uuidv4(),
        name: "Books of Other Religion",
        banglaName: "অন্যান্য ধর্মীয় বই",
        path: "/books?search=other religion books",
      },
      {
        id: uuidv4(),
        name: "Exam Preparation",
        banglaName: "পরীক্ষা প্রস্তুতি",
        path: "/books?search=exam preparation",
      },
      {
        id: uuidv4(),
        name: "Tours & Travels",
        banglaName: "ভ্রমণ বিষয়ক",
        path: "/books?search=tours travels",
      },
      {
        id: uuidv4(),
        name: "Articles",
        banglaName: "প্রবন্ধ",
        path: "/books?search=articles",
      },
      {
        id: uuidv4(),
        name: "Olympiad",
        banglaName: "ওলিম্পিয়াড",
        path: "/books?search=olympiad",
      },
      {
        id: uuidv4(),
        name: "Law",
        banglaName: "আইন বিষয়ক",
        path: "/books?search=law",
      },
      {
        id: uuidv4(),
        name: "Admission",
        banglaName: "ভর্তি বিষয়ক",
        path: "/books?search=admission",
      },
      {
        id: uuidv4(),
        name: "Engineering",
        banglaName: "প্রকৌশল",
        path: "/books?search=engineering",
      },
      {
        id: uuidv4(),
        name: "History & Culture",
        banglaName: "ইতিহাস ও সংস্কৃতি",
        path: "/books?search=history culture",
      },
      {
        id: uuidv4(),
        name: "Computer & Internet",
        banglaName: "কম্পিউটার ও ইন্টারনেট",
        path: "/books?search=computer internet",
      },
      {
        id: uuidv4(),
        name: "Freelancing & Outsourcing",
        banglaName: "ফ্রিল্যান্সিং ও আউটসোর্সিং",
        path: "/books?search=freelancing outsourcing",
      },
      {
        id: uuidv4(),
        name: "Agriculture & Firming",
        banglaName: "কৃষি বিষয়ক",
        path: "/books?search=agriculture firming",
      },
      {
        id: uuidv4(),
        name: "Sports",
        banglaName: "খেলাধুলা",
        path: "/books?search=sports",
      },
      {
        id: uuidv4(),
        name: "Journalism & Mass Media",
        banglaName: "সাংবাদিকতা ও গণমাধ্যম",
        path: "/books?search=journalism mass media",
      },
      {
        id: uuidv4(),
        name: "Math, Science & Technology",
        banglaName: "গণিত, বিজ্ঞান ও প্রযুক্তি",
        path: "/books?search=math-science technology",
      },
      {
        id: uuidv4(),
        name: "Drawing & Painting",
        banglaName: "চিত্র অংকন",
        path: "/books?search=drawing painting",
      },
      {
        id: uuidv4(),
        name: "Architecture",
        banglaName: "স্থাপত্য",
        path: "/books?search=architecture",
      },
      {
        id: uuidv4(),
        name: "Design",
        banglaName: "নকশা",
        path: "/books?search=design",
      },
      {
        id: uuidv4(),
        name: "Photography",
        path: "/books?search=photography",
        banglaName: "আলোকচিত্র বিষয়ক",
      },
      {
        id: uuidv4(),
        name: "Family & Parenting",
        path: "/books?search=family parenting",
        banglaName: "পরিবার ও প্যারেন্টিং",
      },
      {
        id: uuidv4(),
        name: "Society",
        path: "/books?search=society",
        banglaName: "সমাজ",
      },
      {
        id: uuidv4(),
        name: "Environment",
        path: "/books?search=environment",
        banglaName: "পরিবেশ",
      },
      {
        id: uuidv4(),
        name: "West Bengal Books",
        path: "/books?search=west bengal books",
        banglaName: "পশ্চিম বঙ্গের বই",
      },
      {
        id: uuidv4(),
        name: "Awarded Books",
        path: "/books?search=awarded books",
        banglaName: "পুরষ্কারপ্রাপ্ত বই",
      },
      {
        id: uuidv4(),
        name: "Professional",
        path: "/books?search=professional",
        banglaName: "প্রফেশনাল",
      },
      {
        id: uuidv4(),
        name: "Journal",
        path: "/books?search=journal",
        banglaName: "জার্নাল",
      },
      {
        id: uuidv4(),
        name: "Reference",
        path: "/books?search=reference",
        banglaName: "রেফারেন্স",
      },
      {
        id: uuidv4(),
        name: "Animal",
        path: "/books?search=animal",
        banglaName: "প্রাণীকুল",
      },
      {
        id: uuidv4(),
        name: "Fashion & Self-care",
        path: "/books?search=fashion self care",
        banglaName: "ফ্যাশন ও সেল্ফ-কেয়ার",
      },
      {
        id: uuidv4(),
        name: "Business & Investment",
        path: "/books?search=business investment",
        banglaName: "ব্যবসা ও বিনিয়োগ",
      },
      {
        id: uuidv4(),
        name: "Economy",
        path: "/books?search=economy",
        banglaName: "অর্থনীতি",
      },
      {
        id: uuidv4(),
        name: "Language",
        banglaName: "ভাষা বিষয়ক",
        path: "/books?search=language",
      },
      {
        id: uuidv4(),
        name: "Dictionary",
        banglaName: "অভিধান",
        path: "/books?search=dictionary",
      },
      {
        id: uuidv4(),
        name: "Medical Science",
        banglaName: "চিকিৎসা বিজ্ঞান",
        path: "/books?search=medical science",
      },
      {
        id: uuidv4(),
        name: "Cooking",
        banglaName: "রান্না",
        path: "/books?search=cooking",
      },
      {
        id: uuidv4(),
        name: "Songs",
        banglaName: "সঙ্গীত বিষয়ক",
        path: "/books?search=songs",
      },
      {
        id: uuidv4(),
        name: "Movies & Entertainment",
        banglaName: "চলচ্চিত্র ও বিনোদন",
        path: "/books?search=movies entertainment",
      },
      {
        id: uuidv4(),
        name: "Health Care",
        banglaName: "স্বাস্থ্য পরিচর্যা",
        path: "/books?search=health care",
      },
      {
        id: uuidv4(),
        name: "Health & Fitness",
        banglaName: "স্বাস্থ্য এবং ফিটনেস",
        path: "/books?search=health fitness",
      },
      {
        id: uuidv4(),
        name: "Others",
        banglaName: "অন্যান্য",
        path: "/others",
      },
    ],
  },
  {
    title: "Electronics",
    category: "electronics",
    banglaTitle: "ইলেকট্রনিক্স ",
    link: "/electronics-category",
    subCategory: [
      {
        id: uuidv4(),
        name: "Smart Watch",
        path: "/electronics?search=smart watch",
        banglaName: "স্মার্ট ঘড়ি",
      },
    ],
  },

  {
    title: "Academics",
    category: "academics",
    banglaTitle: "পাঠ্যবই",
    link: "/academics",
  },
  {
    title: "Stationaries",
    category: "stationaries",
    banglaTitle: "স্টেশনারী",
    link: "/stationaries-category",
    subCategory: [
      {
        id: uuidv4(),
        name: "Islamic Products",
        path: "/stationaries?search=islamic products",
        banglaName: "ইসলামিক পণ্য",
      },
      {
        id: uuidv4(),
        name: "Diary",
        path: "/stationaries?search=diary",
        banglaName: "ডাইরি",
      },
      {
        id: uuidv4(),
        name: "Notebooks",
        path: "/stationaries?search=notebooks",
        banglaName: "নোটবুক",
      },
      {
        id: uuidv4(),
        name: "Khata",
        path: "/stationaries?search=khata",
        banglaName: "খাতা",
      },
      {
        id: uuidv4(),
        name: "Art Book",
        path: "/stationaries?search=art book",
        banglaName: "আর্ট খাতা",
      },
      {
        id: uuidv4(),
        name: "Sketch Book",
        path: "/stationaries?search=sketch book",
        banglaName: "স্কেচ খাতা",
      },
      {
        id: uuidv4(),
        name: "Pen & Pencils",
        path: "/stationaries?search=pen and pencils",
        banglaName: "Pen & Pencils",
      },
      {
        id: uuidv4(),
        name: "Calculator",
        path: "/stationaries?search=calculator",
        banglaName: "ক্যালকুলেটর",
      },
      {
        id: uuidv4(),
        name: "Geometry Box",
        path: "/stationaries?search=geometry box",
        banglaName: "জ্যামিতি বক্স",
      },
      {
        id: uuidv4(),
        name: "Art Supplies",
        path: "/stationaries?search=art supplies",
        banglaName: "আর্ট সামগ্রী",
      },
      {
        id: uuidv4(),
        name: "Child Development",
        path: "/stationaries?search=child development",
        banglaName: "শিশু বিকাশ",
      },
      {
        id: uuidv4(),
        name: "Colors",
        path: "/stationaries?search=colors",
        banglaName: "রঙ",
      },
      {
        id: uuidv4(),
        name: "Brush",
        path: "/stationaries?search=brush",
        banglaName: "তুলি",
      },
      {
        id: uuidv4(),
        name: "Study Material",
        path: "/stationaries?search=study material",
        banglaName: "স্টাডি ম্যাটেরিয়াল",
      },
      {
        id: uuidv4(),
        name: "Office Supply",
        path: "/stationaries?search=office supply",
        banglaName: "অফিস সাপ্লাই",
      },
    ],
  },
  {
    title: "IELTS",
    category: "IELTS",
    banglaTitle: "আই ই এল টি এস",
    link: "/books?search=IELTS",
  },
  {
    title: "Children",
    category: "children",
    banglaTitle: "শিশু-কিশোর",
    link: "/books?search=children",

    // books: [
    //     {
    //          id: uuidv4(),
    //         name: "Comics",
    //         path: "/books?search=comics",
    //         banglaName: "কমিক্স",
    //     },
    //     {
    //         id: uuidv4(),
    //         name: "Picture Books",
    //         path: "/picture-books",
    //         banglaName: "রঙিন ছবির বই",
    //     },
    //     {
    //         id: uuidv4(),
    //         name: "Child Development",
    //         path: "/child-development",
    //         banglaName: "শিশু বিকাশ",
    //     },
    //     {
    //          id: uuidv4(),
    //         name: "Children Story",
    //         path: "/children-story",
    //         banglaName: "শিশুতোষ গল্প",
    //     },
    // ],
  },
  {
    title: "Kids Zone",
    category: "kids-zone",
    banglaTitle: "কিডস জোন",
    link: "/",
  },
];

export const books = [
  {
    name: "Bangla Classic",
    path: "/books?search=bangla classic",
    banglaName: "চিরায়ত বাংলা",
  },
  {
    name: "Bangla Translate",
    path: "/books?search=bangla translate",
    banglaName: "বাংলায় অনুদিত",
  },
  {
    name: "Mystery",
    path: "/books?search=mystery",
    banglaName: "রহস্য",
  },
  {
    name: "Detective",
    path: "/books?search=detective",
    banglaName: "গোয়েন্দা",
  },
  {
    name: "Adventure",
    path: "/books?search=adventure",
    banglaName: "রোমাঞ্চ",
  },
  {
    name: "Horror",
    path: "/books?search=horror",
    banglaName: "ভৌতিক",
  },
  {
    name: "Thriller",
    path: "/books?search=thriller",
    banglaName: "থ্রিলার",
  },
  {
    name: "Novel",
    path: "/books?search=novel",
    banglaName: "উপন্যাস",
  },
  {
    name: "Poetry",
    path: "/books?search=poetry",
    banglaName: "কবিতা",
  },
  {
    name: "Science Fiction",
    path: "/books?search=science fiction",
    banglaName: "বৈজ্ঞানিক কল্পকাহিনী",
  },
  {
    name: "Society",
    path: "/books?search=society",
    banglaName: "সমাজ",
  },
  {
    name: "Culture",
    path: "/books?search=culture",
    banglaName: "সংস্কৃতি",
  },
  {
    name: "History",
    banglaName: "ইতিহাস",
    path: "/books?search=history",
  },
  {
    name: "Psychology",
    banglaName: "সাইকোলজি",
    path: "/books?search=psychology",
  },
  {
    name: "Parapsychology",
    banglaName: "প্যারা-সাইকোলজি",
    path: "/books?search=parapsychology",
  },
  {
    name: "Muktijuddho",
    banglaName: "মুক্তিযুদ্ধ",
    path: "/books?search=muktijuddho",
  },
  {
    name: "Bangabondhu",
    banglaName: "বঙ্গবন্ধু বিষয়ক",
    path: "/books?search=bangabondhu",
  },
  {
    name: "Bangladesh",
    banglaName: "বাংলাদেশ বিষয়ক",
    path: "/books?search=bangladesh",
  },
  {
    name: "History of Bangladesh",
    banglaName: "বাংলাদেশের ইতিহাস",
    path: "/books?search=history of bangladesh",
  },
  {
    name: "Sarcasm & Satire",
    banglaName: "রম্য ও ব্যঙ্গরচনা",
    path: "/books?search=sarcasm satire",
  },
  {
    name: "Philosophy",
    banglaName: "দর্শন",
    path: "/books?search=philosophy",
  },
  {
    name: "Drama",
    banglaName: "নাটক",
    path: "/books?search=drama",
  },
  {
    name: "Biographies",
    banglaName: "জীবনী",
    path: "/books?search=biographies",
  },
  {
    name: "Auto Biographies",
    banglaName: "আত্ন-জীবনী",
    path: "/books?search=auto biographies",
  },
  {
    name: "Memories",
    banglaName: "স্মৃতিকথা",
    path: "/books?search=memories",
  },
  {
    name: "Interviews",
    banglaName: "সাক্ষাৎকার",
    path: "/books?search=interviews",
  },
  {
    name: "Self-Motivations & Meditations",
    banglaName: "আত্মউন্নয়ন ও মেডিটেশন",
    path: "/books?search=self motivation",
  },
  {
    name: "Politics",
    banglaName: "রাজনীতি",
    path: "/books?search=politics",
  },
  {
    name: "World Politics",
    banglaName: "বিশ্ব রাজনীতি",
    path: "/books?search=world politics",
  },
  {
    name: "Islamic Books",
    banglaName: "ইসলামিক বই",
    path: "/books?search=islamic books",
  },
  {
    name: "Books of Other Religion",
    banglaName: "অন্যান্য ধর্মীয় বই",
    path: "/books?search=other religion books",
  },
  {
    name: "Exam Preparation",
    banglaName: "পরীক্ষা প্রস্তুতি",
    path: "/books?search=exam preparation",
  },
  {
    name: "Tours & Travels",
    banglaName: "ভ্রমণ বিষয়ক",
    path: "/books?search=tours travels",
  },
  {
    name: "Articles",
    banglaName: "প্রবন্ধ",
    path: "/books?search=articles",
  },
  {
    name: "Olympiad",
    banglaName: "ওলিম্পিয়াড",
    path: "/books?search=olympiad",
  },
  {
    name: "Law",
    banglaName: "আইন বিষয়ক",
    path: "/books?search=law",
  },
  {
    name: "Admission",
    banglaName: "ভর্তি বিষয়ক",
    path: "/books?search=admission",
  },
  {
    name: "Engineering",
    banglaName: "প্রকৌশল",
    path: "/books?search=engineering",
  },
  {
    name: "History & Culture",
    banglaName: "ইতিহাস ও সংস্কৃতি",
    path: "/books?search=history culture",
  },
  {
    name: "Computer & Internet",
    banglaName: "কম্পিউটার ও ইন্টারনেট",
    path: "/books?search=computer internet",
  },
  {
    name: "Freelancing & Outsourcing",
    banglaName: "ফ্রিল্যান্সিং ও আউটসোর্সিং",
    path: "/books?search=freelancing outsourcing",
  },
  {
    name: "Agriculture & Firming",
    banglaName: "কৃষি বিষয়ক",
    path: "/books?search=agriculture firming",
  },
  {
    name: "Sports",
    banglaName: "খেলাধুলা",
    path: "/books?search=sports",
  },
  {
    name: "Journalism & Mass Media",
    banglaName: "সাংবাদিকতা ও গণমাধ্যম",
    path: "/books?search=journalism mass media",
  },
  {
    name: "Math, Science & Technology",
    banglaName: "গণিত, বিজ্ঞান ও প্রযুক্তি",
    path: "/books?search=math-science technology",
  },
  {
    name: "Drawing & Painting",
    banglaName: "চিত্র অংকন",
    path: "/books?search=drawing painting",
  },
  {
    name: "Architecture",
    banglaName: "স্থাপত্য",
    path: "/books?search=architecture",
  },
  {
    name: "Design",
    banglaName: "নকশা",
    path: "/books?search=design",
  },
  {
    name: "Photography",
    path: "/books?search=photography",
    banglaName: "আলোকচিত্র বিষয়ক",
  },
  {
    name: "Family & Parenting",
    path: "/books?search=family parenting",
    banglaName: "পরিবার ও প্যারেন্টিং",
  },
  {
    name: "Society",
    path: "/books?search=society",
    banglaName: "সমাজ",
  },
  {
    name: "Environment",
    path: "/books?search=environment",
    banglaName: "পরিবেশ",
  },
  {
    name: "West Bengal Books",
    path: "/books?search=west bengal books",
    banglaName: "পশ্চিম বঙ্গের বই",
  },
  {
    name: "Awarded Books",
    path: "/books?search=awarded books",
    banglaName: "পুরষ্কারপ্রাপ্ত বই",
  },
  {
    name: "Professional",
    path: "/books?search=professional",
    banglaName: "প্রফেশনাল",
  },
  {
    name: "Journal",
    path: "/books?search=journal",
    banglaName: "জার্নাল",
  },
  {
    name: "Reference",
    path: "/books?search=reference",
    banglaName: "রেফারেন্স",
  },
  {
    name: "Animal",
    path: "/books?search=animal",
    banglaName: "প্রাণীকুল",
  },
  {
    name: "Fashion & Self-care",
    path: "/books?search=fashion self care",
    banglaName: "ফ্যাশন ও সেল্ফ-কেয়ার",
  },
  {
    name: "Business & Investment",
    path: "/books?search=business investment",
    banglaName: "ব্যবসা ও বিনিয়োগ",
  },
  {
    name: "Economy",
    path: "/books?search=economy",
    banglaName: "অর্থনীতি",
  },
  {
    name: "Language",
    banglaName: "ভাষা বিষয়ক",
    path: "/books?search=language",
  },
  {
    name: "Dictionary",
    banglaName: "অভিধান",
    path: "/books?search=dictionary",
  },
  {
    name: "Medical Science",
    banglaName: "চিকিৎসা বিজ্ঞান",
    path: "/books?search=medical science",
  },
  {
    name: "Cooking",
    banglaName: "রান্না",
    path: "/books?search=cooking",
  },
  {
    name: "Songs",
    banglaName: "সঙ্গীত বিষয়ক",
    path: "/books?search=songs",
  },
  {
    name: "Movies & Entertainment",
    banglaName: "চলচ্চিত্র ও বিনোদন",
    path: "/books?search=movies entertainment",
  },
  {
    name: "Health Care",
    banglaName: "স্বাস্থ্য পরিচর্যা",
    path: "/books?search=health care",
  },
  {
    name: "Health & Fitness",
    banglaName: "স্বাস্থ্য এবং ফিটনেস",
    path: "/books?search=health fitness",
  },
  {
    name: "Stationaries",
    path: "/stationaries",
    banglaName: "স্টেশনারী",
  },
  {
    name: "Academics",
    path: "/academics",
    banglaName: "পাঠ্যবই",
  },
  {
    name: "Ielts",
    path: "/ielts",
    banglaName: "আই ই এল টি এস",
  },
  {
    name: "Children",
    path: "/children",
    banglaName: "শিশু-কিশোর",
  },

  {
    name: "Kids Zone",
    path: "/books?search=Kids",
    banglaName: "কিডস জোন",
  },

  {
    name: "Others",
    banglaName: "অন্যান্য",
    path: "/others",
  },
];

export const academicSchools = [
  {
    name: "Mastermind",
  },
  {
    name: "South Breeze (Dhanmondi Branch)",
  },
  {
    name: "Tiny Tots_Summerfield International",
  },
  {
    name: "Daffodil International",
  },
];
